﻿AOS.init({
    duration: 1200,
    easing: 'slide',
    once: true
});


jQuery(document).ready(function ($) {

function onScroll(event) {
        var scrollPos = $(document).scrollTop();
        $('#menu-center a').each(function () {
            var currLink = $(this);
            var refElement = $(currLink.attr("href"));
            if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
                $('#menu-center ul li a').removeClass("active");
                currLink.addClass("active");
            }
            else {
                currLink.removeClass("active");
            }
        });
    }


/*Scroll page BEGIN*/
    $(window).scroll(function () {
        var height = $(window).scrollTop();
        if (height > 100) {
            $('#back2Top').fadeIn();
            $('.stickyTopSpace0').addClass('shrink');
        } else {
            $('#back2Top').fadeOut();
            $('.stickyTopSpace0').removeClass('shrink');
        }
    });


    $(document).ready(function () {
        $("#back2Top").click(function (event) {
            event.preventDefault();
            $("html, body").animate({ scrollTop: 0 }, "slow");
            return false;
        });

        $(".stickyTopSpace0").sticky({ topSpacing: 0 });
    });
 /*Scroll page END*/


    //, .site-mobile-menu .site-nav-wrap li a
    var navigation = function () {
        var navToggler = $('.pageMenuToggle');
        $("body").on("click", ".hlMenu li a[href^='#'], .smoothscroll[href^='#']", function (e) {
            e.preventDefault();
            var hash = this.hash;
            $('html, body').animate({
                'scrollTop': $(hash).offset().top
            }, 600, 'easeInOutCirc', function () {
                window.location.hash = hash;
            });
        });
    };
    navigation();


/*Posuvanie obrazkov z opozdenim BEGIN*/
    var siteStellar = function () {
        $(window).stellar({
            responsive: false,
            parallaxBackgrounds: true,
            parallaxElements: true,
            horizontalScrolling: false,
            hideDistantElements: false,
            scrollProperty: 'scroll'
        });
    };
    siteStellar();
/*Posuvanie obrazkov z opozdenim END*/
});